import React, { useState } from "react";
import { CheckoutPage_real } from "../tossPay/Checkout_real";
import { Checkout_self } from "../tossPay/Checkout_self";
import { Divider } from "@mui/material";
import { Margin } from "@mui/icons-material";
import { Navigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CheckoutPage_mob_real } from "../tossPay/Checkout_mob_real";
import { Checkout_self_mob } from "../tossPay/Checkout_self_mob";

const SelfPaymentPage = () => {
  const { price: encodedPrice } = useParams();
  const [price, setPrice] = useState(atob(encodedPrice));
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  if (!price) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {isPc ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            fontSize: "18px",
          }}
        >
          <h2>
            <b>(주)스노우화이트</b> 결제 페이지입니다.
          </h2>
          <br />
          <h2>
            <b>{parseInt(price).toLocaleString("ko-KR")}원</b>을 결제해주세요.
          </h2>

          <Divider sx={{ width: "25%", margin: "20px 0" }} />

          <div
            style={{
              width: "25%",
            }}
          >
            <Checkout_self totalPrice={price} orderName={"카드 결제"} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "18px",
            paddingTop: "100px",
          }}
        >
          <h2>
            <b>(주)스노우화이트</b> 결제 페이지입니다.
          </h2>
          <br />
          <h2>
            <b>{parseInt(price).toLocaleString("ko-KR")}원</b>을 결제해주세요.
          </h2>

          <Divider sx={{ width: "80%", margin: "20px 0" }} />

          <div
            style={{
              width: "100%",
            }}
          >
            <Checkout_self_mob totalPrice={price} orderName={"카드 결제"} />
          </div>
        </div>
      )}
    </>
  );
};

export default SelfPaymentPage;
