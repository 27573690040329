import {
  AddLink,
  AddToPhotos,
  AlignHorizontalLeft,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  ContentCopy,
  Delete,
  DoDisturb,
  Download,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatBoldRounded,
  FormatItalic,
  FormatLineSpacing,
  FormatUnderlined,
  Fullscreen,
  GroupAdd,
  GroupRemove,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LineStyle,
  LinkOff,
  LocalDining,
  Lock,
  LockOpen,
  Opacity,
  Percent,
  Redo,
  RedoRounded,
  ResetTv,
  Restore,
  Save,
  SaveAlt,
  ShoppingCart,
  Undo,
  UndoRounded,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import * as S from "../../styles/new_styles";
import { SketchPicker } from "react-color";
import { BsBack, BsFront } from "react-icons/bs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

const New_EditorToolbar = ({
  editorRef,
  selectedNode,
  setSelectedNode,
  pages,
  currentPage,
}) => {
  const { item_sid } = useParams();
  const [selectedType, setSelectedType] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedStrokeColor, setSelectedStrokeColor] = useState(null);
  const [selectedFontFamily, setSelectedFontFamily] = useState(null);
  const [selectedFontSize, setSelectedFontSize] = useState(null);
  const [selectedAlign, setSelectedAlign] = useState(null);
  const [LineSpacingBoxShow, setLineSpacingBoxShow] = useState(false);
  const [selectedLineHeight, setSelectedLineHeight] = useState(null);
  const [selectedLetterSpacing, setSelectedLetterSpacing] = useState(null);
  const [opacityBoxShow, setOpacityBoxShow] = useState(false);
  const [selectedOpacity, setSelectedOpacity] = useState(null);
  const [colorPickerShow, setColorPickerShow] = useState(false);
  const [lineStyleBoxShow, setLineStyleBoxShow] = useState(false);
  const [isGroup, setIsGroup] = useState(false);

  const { data } = useQuery("userinfo", { enabled: false });
  const isAdmin = data?.USER_GRADE === 9;

  const lineSpacingBoxAnimation = useSpring({
    opacity: LineSpacingBoxShow ? 1 : 0,
    transform: LineSpacingBoxShow ? "scale(1)" : "scale(0)",
    display: LineSpacingBoxShow ? "block" : "none",
    config: { duration: 150 },
  });
  const opacityBoxAnimation = useSpring({
    opacity: opacityBoxShow ? 1 : 0,
    transform: opacityBoxShow ? "scale(1)" : "scale(0)",
    display: opacityBoxShow ? "block" : "none",
    config: { duration: 150 },
  });

  const colorPickerAnimation = useSpring({
    opacity: colorPickerShow ? 1 : 0,
    transform: colorPickerShow ? "scale(1)" : "scale(0)",
    display: colorPickerShow ? "block" : "none",
    config: { duration: 150 },
  });

  const lineStyleBoxAnimation = useSpring({
    opacity: lineStyleBoxShow ? 1 : 0,
    transform: lineStyleBoxShow ? "scale(1)" : "scale(0)",
    display: lineStyleBoxShow ? "block" : "none",
    config: { duration: 150 },
  });

  useEffect(() => {
    // 팝업 닫기
    setLineSpacingBoxShow(false);
    setOpacityBoxShow(false);
    setColorPickerShow(false);

    if (selectedNode && selectedNode !== "그룹") {
      const nodeType = selectedNode.className;
      setSelectedType(nodeType);
      console.log("툴바의 selectedNode 타입:", nodeType);
      console.log("툴바의 selectedNode : ", selectedNode);
      if (selectedNode.nodeType === "Group") {
        setIsGroup(true);
      } else {
        setIsGroup(false);
      }
      if (nodeType === "Text") {
        console.log("텍스트 선택됨");
        setSelectedColor(selectedNode.getFill());
        setSelectedFontFamily(selectedNode.getFontFamily());
        setSelectedFontSize(selectedNode.getFontSize());
        setSelectedLineHeight(selectedNode.getLineHeight());
        setSelectedLetterSpacing(selectedNode.getLetterSpacing());
        setSelectedAlign(selectedNode.getAlign());
        setSelectedOpacity(selectedNode.getOpacity());
      } else if (
        [
          "Circle",
          "Rect",
          "Star",
          "RegularPolygon",
          "Ellipse",
          "Line",
          "Arrow",
          "Path",
          "Wedge",
          "Ring",
        ].includes(nodeType)
      ) {
        setSelectedColor(selectedNode.getFill());
        setSelectedStrokeColor(selectedNode.getStroke());
        setSelectedOpacity(selectedNode.getOpacity());
      } else if (nodeType === "Image") {
        setSelectedOpacity(selectedNode.getOpacity());
      } else {
        setSelectedType(null);
      }
    } else {
      setSelectedType(null);
      setIsGroup(false);
    }
  }, [selectedNode]);
  return (
    <ToolbarWrap>
      <ToolbarInner>
        <ToolbarArrowBox>
          <Tooltip title="실행취소">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.undo();
              }}
            >
              <UndoRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="되돌리기">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.redo();
              }}
            >
              <RedoRounded />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem sx={{ margin: "0 5px" }} />
          <Tooltip title="확대">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.zoomIn();
              }}
            >
              <ZoomIn />
            </IconButton>
          </Tooltip>
          <Tooltip title="초기화">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.zoomReset();
              }}
            >
              <Fullscreen />
            </IconButton>
          </Tooltip>
          <Tooltip title="축소">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.zoomOut();
              }}
            >
              <ZoomOut />
            </IconButton>
          </Tooltip>
        </ToolbarArrowBox>
        <ToolbarDivider />
        <ToolbarStatusBox>
          {selectedType === "Text" ? (
            <>
              <>
                <Tooltip title="색상변경">
                  <IconButton
                    onClick={() => {
                      setColorPickerShow(!colorPickerShow);
                    }}
                  >
                    <ColorBox color={selectedColor} />
                  </IconButton>
                </Tooltip>
                <ColorPickerBox style={colorPickerAnimation}>
                  <SketchPicker
                    color={selectedColor}
                    onChange={(color) => {
                      setSelectedColor(color.hex);
                      selectedNode.setFill(color.hex);
                    }}
                  />
                </ColorPickerBox>
              </>

              <IconButton>
                <Select
                  size="small"
                  sx={{
                    width: "150px",
                    textAlign: "left",
                    fontFamily: selectedFontFamily,
                  }}
                  value={selectedFontFamily}
                  onChange={(e) => {
                    setSelectedFontFamily(e.target.value);
                    selectedNode.setFontFamily(e.target.value);
                  }}
                >
                  <MenuItem sx={{ fontFamily: "Arial" }} value={"Arial"}>
                    Arial
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Noto Sans" }}
                    value={"Noto Sans KR"}
                  >
                    Noto Sans KR
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "Roboto" }} value={"Roboto"}>
                    Roboto
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Open Sans" }}
                    value={"Open Sans"}
                  >
                    Open Sans
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "Lato" }} value={"Lato"}>
                    Lato
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "궁서체" }} value={"궁서체"}>
                    궁서체
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "굴림체" }} value={"굴림체"}>
                    굴림체
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "Anton" }} value={"Anton"}>
                    Anton
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Kavivanar" }}
                    value={"Kavivanar"}
                  >
                    Kavivanar
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Nanum Gothic" }}
                    value={"Nanum Gothic"}
                  >
                    Nanum Gothic
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Nanum Myeongjo" }}
                    value={"Nanum Myeongjo"}
                  >
                    Nanum Myeongjo
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Malgun Gothic" }}
                    value={"Malgun Gothic"}
                  >
                    Malgun Gothic
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Gmarket Sans" }}
                    value={"Gmarket Sans"}
                  >
                    Gmarket Sans
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Pretendard" }}
                    value={"Pretendard"}
                  >
                    Pretendard
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Spoqa Han Sans" }}
                    value={"Spoqa Han Sans"}
                  >
                    Spoqa Han Sans
                  </MenuItem>
                </Select>
              </IconButton>
              <Tooltip title="폰트사이즈">
                <TextField
                  size="small"
                  sx={{ width: "60px", textAlign: "left" }}
                  value={selectedFontSize}
                  onChange={(e) => {
                    setSelectedFontSize(e.target.value);
                    selectedNode.setFontSize(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp") {
                      setSelectedFontSize(parseInt(selectedFontSize) + 1);
                      selectedNode.setFontSize(parseInt(selectedFontSize) + 1);
                      return;
                    } else if (e.key === "ArrowDown") {
                      setSelectedFontSize(parseInt(selectedFontSize) - 1);
                      selectedNode.setFontSize(parseInt(selectedFontSize) - 1);
                      return;
                    }
                  }}
                />
              </Tooltip>
              <FontSizeUpDownBox>
                <button
                  onClick={() => {
                    setSelectedFontSize(parseInt(selectedFontSize) + 1);
                    selectedNode.setFontSize(parseInt(selectedFontSize) + 1);
                  }}
                >
                  <KeyboardArrowUp />
                </button>
                <button
                  onClick={() => {
                    setSelectedFontSize(parseInt(selectedFontSize) - 1);
                    selectedNode.setFontSize(parseInt(selectedFontSize) - 1);
                  }}
                >
                  <KeyboardArrowDown />
                </button>
              </FontSizeUpDownBox>
              <Tooltip title="정렬">
                <IconButton
                  onClick={() => {
                    switch (selectedAlign) {
                      case "left":
                        setSelectedAlign("center");
                        selectedNode.setAlign("center");
                        break;
                      case "center":
                        setSelectedAlign("right");
                        selectedNode.setAlign("right");
                        break;
                      case "right":
                        setSelectedAlign("left");
                        selectedNode.setAlign("left");
                        break;
                    }
                  }}
                >
                  {selectedAlign === "center" ? <FormatAlignCenter /> : null}
                  {selectedAlign === "left" ? <FormatAlignLeft /> : null}
                  {selectedAlign === "right" ? <FormatAlignRight /> : null}
                </IconButton>
              </Tooltip>
              <Tooltip title="굵게">
                <IconButton
                  onClick={() => {
                    const style = selectedNode.getFontStyle();
                    if (style.includes("bold")) {
                      selectedNode.setFontStyle(style.replace("bold", ""));
                    } else {
                      selectedNode.setFontStyle(style + " bold");
                    }
                  }}
                >
                  <FormatBold />
                </IconButton>
              </Tooltip>
              <Tooltip title="기울임">
                <IconButton
                  onClick={() => {
                    const style = selectedNode.getFontStyle();
                    if (style.includes("italic")) {
                      selectedNode.setFontStyle(style.replace("italic", ""));
                    } else {
                      selectedNode.setFontStyle(style + " italic");
                    }
                  }}
                >
                  <FormatItalic />
                </IconButton>
              </Tooltip>
              <Tooltip title="밑줄">
                <IconButton
                  onClick={() => {
                    const decoration = selectedNode.getTextDecoration();
                    if (decoration.includes("underline")) {
                      selectedNode.setTextDecoration(
                        decoration.replace("underline", "")
                      );
                    } else {
                      selectedNode.setTextDecoration(decoration + " underline");
                    }
                  }}
                >
                  <FormatUnderlined />
                </IconButton>
              </Tooltip>
              <>
                <Tooltip title="행간">
                  <IconButton
                    onClick={() => {
                      setLineSpacingBoxShow(!LineSpacingBoxShow);
                    }}
                  >
                    <FormatLineSpacing />
                  </IconButton>
                </Tooltip>
                <LineSpacingBox style={lineSpacingBoxAnimation}>
                  <div>
                    <div>
                      <p>Line Height</p>
                      <div>
                        <TextField
                          size="small"
                          sx={{ width: "75px" }}
                          value={selectedLineHeight}
                          type="number"
                          onChange={(e) => {
                            setSelectedLineHeight(parseInt(e.target.value));
                            selectedNode.setLineHeight(
                              parseInt(e.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <p>Letter spacing</p>
                      <div>
                        <TextField
                          size="small"
                          sx={{ width: "75px" }}
                          value={selectedLetterSpacing}
                          type="number"
                          onChange={(e) => {
                            setSelectedLetterSpacing(parseInt(e.target.value));
                            selectedNode.setLetterSpacing(
                              parseInt(e.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </LineSpacingBox>
              </>
            </>
          ) : [
              "Circle",
              "Rect",
              "Star",
              "RegularPolygon",
              "Ellipse",
              "Path",
              "Wedge",
              "Ring",
            ].includes(selectedType) ? (
            <>
              <>
                <Tooltip title="색상변경">
                  <IconButton
                    onClick={() => {
                      setLineStyleBoxShow(false);
                      setColorPickerShow(!colorPickerShow);
                    }}
                  >
                    <ColorBox color={selectedColor} />
                  </IconButton>
                </Tooltip>
                <ColorPickerBox style={colorPickerAnimation}>
                  <SketchPicker
                    color={selectedColor}
                    onChange={(color) => {
                      console.log("color : ", color);
                      console.log("color.rgb : ", color.rgb);
                      console.log("color.hex : ", color.hex);
                      setSelectedColor(
                        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      );
                      selectedNode.setFill(
                        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      );
                    }}
                  />
                </ColorPickerBox>
              </>
            </>
          ) : ["Line", "Arrow"].includes(selectedType) ? (
            <>
              <IconButton
                onClick={() => {
                  setLineStyleBoxShow(false);
                  setColorPickerShow(!colorPickerShow);
                }}
              >
                <ColorBox color={selectedStrokeColor} />
              </IconButton>
              <ColorPickerBox style={colorPickerAnimation}>
                <SketchPicker
                  color={selectedStrokeColor}
                  onChange={(color) => {
                    console.log("color : ", color);
                    console.log("color.rgb : ", color.rgb);
                    console.log("color.hex : ", color.hex);
                    setSelectedStrokeColor(
                      `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                    );
                    selectedNode.setStroke(
                      `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                    );
                    selectedNode.setFill(
                      `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                    );
                  }}
                />
              </ColorPickerBox>
            </>
          ) : ["그룹", "Group"].includes(selectedNode) || isGroup ? (
            <>
              {/* <IconButton
                onClick={() => {
                  console.log(editorRef.current);
                  editorRef.current[
                    currentPage
                  ].current.functions.groupObjects();
                }}
              >
                <AddLink />
              </IconButton>
              <IconButton
                onClick={() => {
                  console.log(editorRef.current);
                  editorRef.current[
                    currentPage
                  ].current.functions.unGroupObjects();
                }}
              >
                <LinkOff />
              </IconButton> */}
            </>
          ) : null}
        </ToolbarStatusBox>
        <ToolbarUtillBox>
          <>
            <Tooltip title="앞으로 보이기">
              <IconButton
                onClick={() => {
                  editorRef.current[
                    currentPage
                  ].current.functions.bringToFront();
                }}
              >
                <BsFront />
              </IconButton>
            </Tooltip>
            <Tooltip title="뒤로 보이기">
              <IconButton
                onClick={() => {
                  editorRef.current[currentPage].current.functions.sendToBack();
                }}
              >
                <BsBack />
              </IconButton>
            </Tooltip>
            <Tooltip title="투명도">
              <IconButton
                onClick={() => {
                  setOpacityBoxShow(!opacityBoxShow);
                }}
              >
                <Opacity />
              </IconButton>
            </Tooltip>
            <OpacityBox style={opacityBoxAnimation}>
              <div>
                <p>투명도</p>
                <div>
                  <Slider
                    sx={{
                      width: "150px",
                      margin: "0 auto",
                    }}
                    value={1 - selectedOpacity}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => {
                      setSelectedOpacity(1 - e.target.value);
                      selectedNode.setOpacity(1 - e.target.value);
                    }}
                  />
                </div>
              </div>
            </OpacityBox>
          </>
          <Tooltip title="복제">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.addClone();
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title="삭제">
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.deleteObject();
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <ToolbarDivider />
          {isAdmin ? (
            <IconButton
              onClick={() => {
                editorRef.current[currentPage].current.functions.savePDF();
              }}
            >
              <Download
                sx={{
                  marginRight: "5px",
                }}
              />
              <Typography>Download</Typography>
            </IconButton>
          ) : null}
          <IconButton
            onClick={async () => {
              const saveData = [];
              pages.map((page) => {
                saveData.push(
                  editorRef.current[page.id].current.functions.saveData()
                );
              });
              console.log(saveData);
              const res = await axios.patch(
                process.env.REACT_APP_DB_HOST + "/api/custom_prod",
                {
                  data1: saveData,
                  item_sid: item_sid,
                }
              );
              if (res.status === 200) {
                alert("저장되었습니다.");
                return true;
              } else {
                alert("저장실패하였습니다.");
                return false;
              }
            }}
          >
            <SaveAlt
              sx={{
                marginRight: "5px",
              }}
            />
            <Typography>저장하기</Typography>
          </IconButton>
          <ToolbarDivider />
          <IconButton
            onClick={async () => {
              const saveData = [];
              pages.map((page) => {
                saveData.push(
                  editorRef.current[page.id].current.functions.saveData()
                );
              });
              console.log(saveData);
              const res = await axios.patch(
                process.env.REACT_APP_DB_HOST + "/api/custom_prod",
                {
                  data1: saveData,
                  item_sid: item_sid,
                }
              );
              if (res.status === 200) {
                alert("저장되었습니다.");
                window.location.href = "/cart";
                return true;
              } else {
                alert("저장실패하였습니다.");
                return false;
              }
            }}
          >
            <ShoppingCart
              sx={{
                marginRight: "5px",
              }}
            />
            <Typography>장바구니</Typography>
          </IconButton>
        </ToolbarUtillBox>
      </ToolbarInner>
    </ToolbarWrap>
  );
};

export default New_EditorToolbar;

const ToolbarDivider = styled.div`
  float: left;
  width: 1px;
  margin: 0 5px;
  height: 100%;
  background-color: rgba(17, 20, 24, 0.1);
`;

const ToolbarWrap = styled.div`
  white-space: nowrap;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1);
  height: 50px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 10;

  & p {
    font-size: 14px;
    font-weight: 500;
    font-family: "Noto Sans KR", sans-serif;
  }

  & svg {
    &:hover {
      color: #333333;
    }
  }
`;

const ToolbarInner = styled.div`
  width: 100%;
  height: 100%;
`;

const ToolbarArrowBox = styled.div`
  float: left;
  display: flex;
  align-items: center;
  height: 50px;

  & button {
    width: 30px;
    height: 30px;
  }
`;

const ToolbarStatusBox = styled.div`
  float: left;
  display: flex;
  align-items: center;
  height: 50px;
`;

const ToolbarUtillBox = styled.div`
  float: right;
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 25px;
`;

const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
`;

const FontSizeUpDownBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;

  & button {
    width: 20px;
    height: 20px;
    & svg {
      color: #777;
      &:hover {
        color: #333;
      }
    }
  }
`;

const LineSpacingBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    & > div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > p {
        padding-left: 1em;
      }
      & > div {
        padding-right: 0.5em;
      }
    }
  }
`;
const OpacityBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    & > div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > p {
        padding-left: 1em;
      }
      & > div {
        padding-right: 0.5em;
      }
    }
  }
`;

const ColorPickerBox = styled(animated.div)`
  z-index: 1005;
  position: relative;
  & > div {
    position: absolute;
    z-index: 1005;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 300px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
`;

const LineStyleBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 300px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
`;
