import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  formatDateAndTime,
  formatDateAndTime_KR,
  formatNumber,
  formatORDER_PAYMENT_TYPE,
} from "../../hooks/Utill";
import { Padding, Print } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";

const AdminOrderDetail = ({ openPopup }) => {
  const { order_sid } = useParams();

  const [orderData, setOrderData] = useState();
  const [customProdData, setCustomProdData] = useState();

  const initdb = async () => {
    const res_order = await axios.get(process.env.REACT_APP_DB_HOST + "/api/order_detail", {
      params: {
        order_sid: order_sid,
      },
    });
    const item_sids = res_order.data.ITEM_SIDS.split(",");
    console.log(res_order.data.ITEM_SIDS.split(","));

    const res_custom_prod = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/orderlist/item",
      {
        item_sids: item_sids,
      }
    );

    setCustomProdData(res_custom_prod.data);
    setOrderData(res_order.data);
  };

  useEffect(() => {
    initdb();
  }, []);

  const HeadCell = styled(TableCell)`
    font-weight: 550;
  `;

  const Printref = useRef();

  const handlePrint = useReactToPrint({
    content: () => Printref.current,
    documentTitle: "웹주문서",
    // onAfterPrint: () => alert("파일 다운로드 후 알림창 생성 가능"),
  });

  const renderOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "결제 대기";
      case 2:
        return "결제 완료";
      case 3:
        return "제작 중";
      case 4:
        return "배송 중";
      case 5:
        return "배송 완료";
      case 9:
        return "취소";
      default:
        return "Code error";
    }
  };

  return (
    <>
      {orderData !== undefined && customProdData !== undefined ? (
        <>
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#1976d2",
              color: "#fff",
              padding: "24px",
              fontSize: "24px",
            }}
          >
            주문 상세
          </Box>
          <Box sx={{}}>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#fafafa" }}>
                  <TableRow>
                    <HeadCell>주문일</HeadCell>
                    <HeadCell>결제일</HeadCell>
                    <HeadCell>주문번호</HeadCell>
                    <HeadCell>주문자</HeadCell>
                    <HeadCell>상품명</HeadCell>
                    <HeadCell>결제금액</HeadCell>
                    <HeadCell>결제수단</HeadCell>
                    <HeadCell>결제상태</HeadCell>
                    <HeadCell>택배사</HeadCell>
                    <HeadCell>요청사항</HeadCell>
                    <HeadCell>현금 영수증</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{formatDateAndTime_KR(orderData?.ORDER_DATE)}</TableCell>
                    <TableCell>{formatDateAndTime_KR(orderData?.ORDER_PAYMENT_DATE)}</TableCell>
                    <TableCell>{orderData?.ORDER_SID}</TableCell>
                    <TableCell>{orderData?.ORDER_NM}</TableCell>
                    <TableCell>
                      {orderData?.ORDER_CORE_PROD}{" "}
                      {customProdData?.length > 1 ? `외 ${customProdData?.length - 1}건` : ""}
                    </TableCell>
                    <TableCell>{formatNumber(orderData?.ORDER_AMOUNT)}</TableCell>
                    <TableCell>
                      <p>{formatORDER_PAYMENT_TYPE(orderData?.ORDER_PAYMENT_TYPE)}</p>
                      <p style={{ color: "#777", fontSize: "12px" }}>
                        {orderData?.CASH_DEPOSITOR_NAME &&
                          "(" + orderData?.CASH_DEPOSITOR_NAME + ")"}
                      </p>
                    </TableCell>
                    <TableCell>{renderOrderStatus(orderData?.ORDER_STATUS)}</TableCell>
                    <TableCell>
                      <p>{orderData?.ORDER_LOGIS_NM}</p>
                      <p>{orderData?.ORDER_LOGIS_NO}</p>
                      <Button
                        variant="contained"
                        color="info"
                        size="small"
                        onClick={() => {
                          openPopup("logisDetail", {
                            ORDER_LOGIS_NM: orderData?.ORDER_LOGIS_NM,
                            ORDER_LOGIS_NO: orderData?.ORDER_LOGIS_NO,
                          });
                        }}
                      >
                        배송추적
                      </Button>
                    </TableCell>
                    <TableCell>{orderData?.ORDER_REQ}</TableCell>
                    <TableCell>
                      <p>{orderData?.CASH_RECEIPT_TYPE}</p>
                      <p>{orderData?.CASH_RECEIPT_NO}</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#fafafa" }}>
                  <TableRow>
                    <HeadCell sx={{ textAlign: "center" }}>메모</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }}>{orderData?.ORDER_MEMO}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {customProdData?.length > 0 ? (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#fff",
                  color: "#333",
                  padding: "24px",
                  fontSize: "24px",
                }}
              >
                상품 상세
              </Box>
              <Box sx={{}}>
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#fafafa" }}>
                      <TableRow>
                        <HeadCell>주문상품</HeadCell>
                        <HeadCell>옵션</HeadCell>
                        <HeadCell>디자인</HeadCell>
                        <HeadCell>수량</HeadCell>
                        <HeadCell>금액</HeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customProdData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.PROD_NM}</TableCell>
                          <TableCell>{item.ITEM_OPTION}</TableCell>
                          <TableCell>
                            <span
                              style={{
                                color: "#007bff",
                                paddingRight: "10px",
                              }}
                            >
                              {item.ITEM_FILE_NAME}
                            </span>
                            <Button
                              variant="contained"
                              onClick={() => {
                                // window.open(item.ITEM_FILE_LOCATION);
                                const link = document.createElement("a");
                                link.href = item.ITEM_FILE_LOCATION;
                                link.download = item.ITEM_FILE_NAME || "시안파일.pdf"; // 기본 파일명 지정
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                              size="small"
                            >
                              시안 확인
                            </Button>
                          </TableCell>
                          <TableCell>
                            {formatNumber(item.ITEM_QUANTITY)} / {item.ITEM_COUNT}건
                          </TableCell>
                          <TableCell>{formatNumber(item.ITEM_AMOUNT)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#fff",
                  color: "#333",
                  padding: "24px",
                  fontSize: "24px",
                }}
              >
                상품 상세
              </Box>
              <Box sx={{}}>
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#fafafa" }}>
                      <TableRow>
                        <HeadCell>주문상품</HeadCell>
                        <HeadCell>옵션</HeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{orderData?.ORDER_CORE_PROD}</TableCell>
                        <TableCell>{orderData?.ORDER_CORE_OPTION}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#fff",
              color: "#333",
              padding: "24px",
              fontSize: "24px",
            }}
          >
            배송지 상세
          </Box>
          <Box sx={{}}>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: "#fafafa" }}>
                  <TableRow>
                    <HeadCell>배송지 주소</HeadCell>
                    <HeadCell>받는분</HeadCell>
                    <HeadCell>받는분 전화번호</HeadCell>
                    <HeadCell>요청사항</HeadCell>
                    <HeadCell>택배사</HeadCell>
                    <HeadCell>송장번호</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {orderData?.ORDER_ADDRESS} {orderData?.ORDER_ADD_ADDRESS} (
                      {orderData?.ORDER_POSTCODE})
                    </TableCell>
                    <TableCell>{orderData?.ORDER_REC}</TableCell>
                    <TableCell>{orderData?.REC_TEL}</TableCell>
                    <TableCell>{orderData?.ORDER_REQ}</TableCell>
                    <TableCell>{orderData?.ORDER_LOGIS_NM}</TableCell>
                    <TableCell>{orderData?.ORDER_LOGIS_NO}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
      <PrintBtnWarp>
        <Button variant="contained" onClick={handlePrint} startIcon={<Print />}>
          프린트하기
        </Button>
      </PrintBtnWarp>

      <PrintBox ref={Printref}>
        <h1>웹 주문서</h1>
        <table>
          <tr>
            <th>주문일</th>
            <td>{formatDateAndTime_KR(orderData?.ORDER_DATE)}</td>
          </tr>
          <tr>
            <th>주문자</th>
            <td>{orderData?.ORDER_NM}</td>
          </tr>
          <tr>
            <th>주소</th>
            <td>
              {orderData?.ORDER_ADDRESS} {orderData?.ORDER_ADD_ADDRESS} ({orderData?.ORDER_POSTCODE}
              )
            </td>
          </tr>
          <tr>
            <th>받는분</th>
            <td>
              {orderData?.ORDER_REC} ({orderData?.REC_TEL})
            </td>
          </tr>
        </table>
        {customProdData?.map((item, index) => (
          <>
            <Divider sx={{ margin: "24px auto", width: "80%" }} />
            <table>
              <tr key={index}>
                <th>주문상품</th>
                <td>{item.PROD_NM}</td>
              </tr>
              <tr>
                <th>디자인명</th>
                <td>{item.ITEM_FILE_NAME}</td>
              </tr>
              <tr>
                <th>옵션</th>
                <td style={{ fontSize: "14px" }}>{item.ITEM_OPTION}</td>
              </tr>
              <tr>
                <th>수량</th>
                <td>
                  {item.ITEM_QUANTITY} / {item.ITEM_COUNT}건
                </td>
              </tr>
            </table>
          </>
        ))}
        {customProdData?.length === 0 && (
          <>
            <Divider sx={{ margin: "24px auto", width: "80%" }} />
            <table>
              <tr>
                <th>주문상품</th>
                <td>{orderData?.ORDER_CORE_PROD}</td>
              </tr>
              <tr>
                <th>옵션</th>
                <td style={{ fontSize: "14px" }}>{orderData?.ORDER_CORE_OPTION}</td>
              </tr>
            </table>
          </>
        )}
      </PrintBox>
    </>
  );
};

export default AdminOrderDetail;

const PrintBtnWarp = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const PrintBox = styled.div`
  display: none;
  padding: 48px;

  & h1 {
    font-size: 24px;
    font-weight: 550;
    text-align: center;
  }

  & table {
    width: 80%;
    margin: 0 auto;
    margin-top: 24px;
    border: 1px solid #000;
    & th {
      width: 150px;
      text-align: center;
      border: 1px solid #000;
      padding: 10;
      font-size: 20px;
      font-weight: 550;
      vertical-align: middle;
    }
    & td {
      text-align: center;
      border: 1px solid #000;
      padding: 10px;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  @media print {
    display: block;
  }
`;
