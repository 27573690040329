import React, { Suspense, useEffect, useState } from "react";
import AdminCate from "../components/admin/AdminCate";
import AdminProd from "../components/admin/AdminProd";
import AdminOrder from "../components/admin/AdminOrder";

import AdminOption from "../components/admin/AdminOption";
import AdminBoard from "../components/admin/AdminBoard";

import * as S from "../styles/new_styles";
import AdminSideBar from "../components/admin/AdminSideBar";
import { CircularProgress, Drawer } from "@mui/material";
import ResponsiveDrawer from "../components/admin/ResponsiveDrawer";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "@coreui/coreui/dist/css/coreui.min.css";

const container =
  window !== undefined ? () => window().document.body : undefined;

const AdminPage = ({ openPopup }) => {
  const { data } = useQuery("userinfo", { enabled: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkUserGrade();
    if (data === undefined) {
      console.log("data is undefined");
      setTimeout(() => {
        checkUserGrade();
      }, 1000);
    }
  }, [data]);

  const checkUserGrade = async () => {
    if (data !== undefined) {
      if (data?.USER_GRADE !== 9) {
        window.location.href = "/";
      } else {
        setIsAdmin(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {/* <S.AdminHeaderWrapper></S.AdminHeaderWrapper> */}
      {isLoading && !isAdmin ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <ResponsiveDrawer openPopup={openPopup} />
      )}
    </>
  );
};

export default AdminPage;

// <S.AdminFlexBox>
// <S.AdminFlexLeft>{/* <AdminSideBar /> */}</S.AdminFlexLeft>
// <S.AdminFlexMiddle>
//   <AdminBoard />
//   <AdminOption openPopup={openPopup} />
//   <AdminOrder openPopup={openPopup} />
//   <AdminCate />
//   <AdminProd />
// </S.AdminFlexMiddle>
// <S.AdminFlexRight></S.AdminFlexRight>
// </S.AdminFlexBox>
