import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { formatDateAndTime_KR, formatYYMMDD_TIME } from "../../hooks/Utill";
import {
  DataGrid,
  GridAddIcon,
  GridDeleteIcon,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import axios from "axios";
import {
  Box,
  IconButton,
  Select,
  SpeedDial,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { Button } from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useSpring, animated } from "react-spring";
import {
  Add,
  AddBox,
  PermPhoneMsg,
  Receipt,
  ReceiptLong,
  RecordVoiceOver,
} from "@mui/icons-material";

export const AdminStockContext = createContext();

export const useAdminStock = () => {
  const context = useContext(AdminStockContext);
  if (!context) {
    throw new Error("useAdminStock must be used within an AdminStockProvider");
  }
  return context;
};

const AdminStock = () => {
  const [dataRows, setDataRows] = useState([]);
  const [snackbar, setSnackbar] = useState(false);
  const [tabValue, setTabValue] = useState("추가/수정");

  //모바일 추가 스와이프
  const [mobSlideDown, setMobSlideDown] = useState(false);
  const [mobSlideDown2, setMobSlideDown2] = useState(false);
  const slideDownAnimation = useSpring({
    position: "absolute",
    width: "100%",
    backgroundColor: "#fff",
    zIndex: 1000,
    top: !mobSlideDown ? -500 : -30,
    config: { tension: 200, friction: 20 },
    paddingTop: "60px",
    paddingBottom: "30px",
  });

  const slideDownAnimation2 = useSpring({
    position: "absolute",
    width: "100%",
    overflowY: "scroll",
    backgroundColor: "#fff",
    zIndex: 1000,
    height: "400px",
    top: !mobSlideDown2 ? -500 : -30,
    config: { tension: 200, friction: 20 },
    paddingTop: "30px",
  });

  //추가 탭
  const [name, setName] = useState("");
  const [gram, setGram] = useState("");
  const [description, setDescription] = useState("");
  const [etc, setEtc] = useState("");
  const [qty, setQty] = useState("");

  const apiRef = useGridApiRef();

  const isPc = useMediaQuery({ minWidth: 768 });

  // const initdb = async () => {
  //   const res = (await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/paper_stock")).data;
  //   console.log(res);

  //   setDataRows(res);
  // };

  const initdb = useCallback(async () => {
    const res = (await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/paper_stock")).data;
    console.log(res);
    setDataRows(res);
  }, []); // 의존성 배열이 비어있으므로 컴포넌트가 마운트될 때만 생성됨

  const contextValue = useMemo(
    () => ({
      initdb,
    }),
    [initdb]
  );

  useEffect(() => {
    initdb();
  }, []);

  //그리드 툴바
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Button
            startIcon={<GridDeleteIcon />}
            onClick={async () => {
              console.log(apiRef.current.getSelectedRows());

              if (apiRef.current.getSelectedRows().size < 1) {
                setSnackbar({
                  open: true,
                  children: "삭제할 용지를 선택해주세요.",
                  severity: "info",
                });
                return;
              }

              const names = [];
              const grams = [];
              apiRef.current.getSelectedRows().forEach((el) => {
                names.push(el.NAME);
                grams.push(el.GRAM);
              });

              const confirm = window.confirm(`${names.join(", ")} 삭제하시겠습니까?`);
              if (confirm) {
                const res = await axios.delete(
                  process.env.REACT_APP_DB_HOST + "/api/admin/paper_stock",
                  {
                    data: {
                      names: names,
                      grams: grams,
                    },
                  }
                );
                if (res.status === 200) {
                  alert(res.data.message);
                  await initdb();
                  apiRef.current.selectRow(null, false, true);
                }
              }
            }}
          >
            삭제
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    <AdminStockContext.Provider value={contextValue}>
      {isPc ? (
        <Box display="flex" justifyContent="center" alignItems="start" gap={2}>
          <DataGrid
            apiRef={apiRef}
            rows={dataRows}
            columns={columns}
            rowHeight={38}
            sx={{
              fontSize: "12px",
              height: "calc(100vh - 100px)",
              width: "50%",
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection
            disableRowSelectionOnClick
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onCellDoubleClick={(params) => {
              setName(params.row.NAME);
              setGram(params.row.GRAM);
              setQty(params.row.QTY);
              setDescription(params.row.DESCRIPTION);
              setEtc(params.row.ETC);
            }}
          />
          <Box
            sx={{
              width: "30%",
              marginRight: "20%",
              border: "1px solid #ddd",
              height: "calc(100vh - 100px)",
              padding: "25px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, value) => setTabValue(value)}
              centered
              variant="fullWidth"
            >
              <Tab label="추가/수정" value="추가/수정" />
              <Tab label="기록" value="기록" />
            </Tabs>
            <SystemBox>
              {tabValue === "추가/수정" && (
                <AddPaper
                  name={name}
                  setName={setName}
                  gram={gram}
                  setGram={setGram}
                  qty={qty}
                  setQty={setQty}
                  description={description}
                  setDescription={setDescription}
                  etc={etc}
                  setEtc={setEtc}
                  apiRef={apiRef}
                  setSnackbar={setSnackbar}
                />
              )}
              {tabValue === "기록" && (
                <Box sx={{ height: "100%", overflow: "auto" }}>
                  <RecordPaper />
                </Box>
              )}
            </SystemBox>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ width: "100%", height: "90vh", paddingTop: "30px" }}>
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 999,
                display: mobSlideDown || mobSlideDown2 ? "block" : "none",
              }}
              onClick={() => {
                setMobSlideDown(false);
                setMobSlideDown2(false);
              }}
            ></Box>
            <animated.div style={slideDownAnimation}>
              <AddPaper
                name={name}
                setName={setName}
                gram={gram}
                setGram={setGram}
                qty={qty}
                setQty={setQty}
                description={description}
                setDescription={setDescription}
                etc={etc}
                setEtc={setEtc}
                apiRef={apiRef}
                setSnackbar={setSnackbar}
              />
            </animated.div>

            <animated.div style={slideDownAnimation2}>
              <RecordPaper />
            </animated.div>
            <Box sx={{ height: "calc(100vh - 150px)" }}>
              <DataGrid
                apiRef={apiRef}
                rows={dataRows}
                columns={columns}
                rowHeight={38}
                sx={{
                  fontSize: "12px",
                  height: "calc(100vh - 120px)",
                  width: "90%",
                  margin: "0 auto",
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                onCellDoubleClick={(params) => {
                  setName(params.row.NAME);
                  setGram(params.row.GRAM);
                  setQty(params.row.QTY);
                  setDescription(params.row.DESCRIPTION);
                  setEtc(params.row.ETC);
                  setMobSlideDown(true);
                }}
                onCellClick={(params) => {
                  // setName(params.row.NAME);
                  // setQty(params.row.QTY);
                  // setDescription(params.row.DESCRIPTION);
                  // setEtc(params.row.ETC);
                  // setMobSlideDown(true);
                }}
              />
            </Box>
          </Box>
          <IconButton
            onClick={() => setMobSlideDown(!mobSlideDown)}
            sx={{ position: "absolute", bottom: 20, right: 20 }}
          >
            <AddBox color="primary" fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => setMobSlideDown2(!mobSlideDown2)}
            sx={{ position: "absolute", bottom: 20, right: 60 }}
          >
            <ReceiptLong color="primary" fontSize="large" />
          </IconButton>
        </>
      )}

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar(false)}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(false)} />
        </Snackbar>
      )}
    </AdminStockContext.Provider>
  );
};

export default AdminStock;

const columns = [
  {
    field: "NAME",
    headerName: "용지",
    width: 95,
    editable: false,
  },
  {
    field: "GRAM",
    headerName: "평량",
    width: 95,
    editable: false,
  },
  {
    field: "QTY",
    headerName: "재고",
    width: 95,
    editable: false,
  },
  {
    field: "DESCRIPTION",
    headerName: "설명",
    width: 150,
    editable: false,
  },
  {
    field: "ETC",
    headerName: "메모",
    width: 150,
    editable: false,
  },
  {
    field: "REGDATE",
    headerName: "날짜",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return `${formatYYMMDD_TIME(params.value)}`;
    },
  },
];

const SystemBox = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 10px;
  padding: 10px;
`;

// 추가
const AddPaper = ({
  name,
  setName,
  gram,
  setGram,
  qty,
  setQty,
  description,
  setDescription,
  etc,
  setEtc,
  apiRef,
  setSnackbar,
}) => {
  const { initdb } = useAdminStock();

  const handleAdd = async () => {
    if (name === "") {
      setSnackbar({
        open: true,
        children: "용지를 입력해주세요",
        severity: "info",
      });
      return;
    }
    if (gram === "") {
      setSnackbar({
        open: true,
        children: "평량을 입력해주세요",
        severity: "info",
      });
      return;
    }
    if (qty === "") {
      setSnackbar({
        open: true,
        children: "재고를 입력해주세요",
        severity: "info",
      });
      return;
    }

    const res = await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/paper_stock", {
      name: name,
      gram: gram,
      qty: qty,
      description: description,
      etc: etc,
    });
    if (res.status === 200) {
      setSnackbar({
        open: true,
        children: res.data.message,
        severity: "success",
      });
      await initdb();
      apiRef.current.selectRow(null, false, true);
    }
  };

  ///api/admin/paper_stock

  return (
    <Box sx={{ display: "flex", gap: "15px", flexDirection: "column", alignItems: "center" }}>
      <TextField
        label="용지"
        sx={{ width: "80%" }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="평량(g)"
        sx={{ width: "80%" }}
        value={gram}
        onChange={(e) => setGram(e.target.value.replace(/[^0-9]/g, ""))}
        type="tel"
      />
      <TextField
        label="재고"
        sx={{ width: "80%" }}
        type="number"
        value={qty}
        onChange={(e) => setQty(e.target.value.replace(/[^0-9.]/g, ""))}
      />
      <TextField
        label="설명 ex) 묶음, 박스"
        sx={{ width: "80%" }}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        label="메모"
        sx={{ width: "80%" }}
        value={etc}
        onChange={(e) => setEtc(e.target.value)}
      />
      <Button variant="contained" sx={{ width: "80%" }} onClick={handleAdd}>
        추가
      </Button>
    </Box>
  );
};

// 기록
const RecordPaper = () => {
  const [historyRows, setHistoryRows] = useState([]);

  const initdb = async () => {
    const res = (await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/paper_stock_history"))
      .data;

    setHistoryRows(res);
  };

  useEffect(() => {
    initdb();
  }, []);

  return (
    <Box>
      <Table
        size="small"
        sx={{ "& th": { fontSize: "12px" }, "& td": { fontSize: "12px", padding: "5px" } }}
      >
        <TableHead>
          <TableRow>
            <TableCell>용지</TableCell>
            <TableCell>재고</TableCell>
            <TableCell>증감</TableCell>
            <TableCell>메모</TableCell>
            <TableCell>날짜</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyRows.map((el, index) => (
            <TableRow key={index}>
              <TableCell>
                {el.NAME} {el.GRAM}
              </TableCell>
              <TableCell>{el.QTY}</TableCell>
              <TableCell>{el.CHANGE_TYPE}</TableCell>
              <TableCell>{el.ETC}</TableCell>
              <TableCell>{formatYYMMDD_TIME(el.REGDATE)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
