export const formatDate = (dateString) => {
  if (dateString === null) {
    return "";
  }
  if (!dateString) {
    return `-`;
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatTime = (dateString) => {
  if (dateString === null) {
    return "";
  }
  if (!dateString) {
    return `-`;
  }
  const date = new Date(dateString);
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  const second = date.getSeconds().toString().padStart(2, "0");
  return `${hour}:${minute}:${second}`;
};

export const formatDateAndTime = (dateString) => {
  if (dateString === null) {
    return "";
  }
  return new Date(dateString)
    .toISOString("ko-KR")
    .replace("T", " / ")
    .replace("Z", " ")
    .replace(/\.\d{3}/, "");
};

export const formatDateAndTime_KR = (dateString) => {
  if (dateString === null) {
    return "";
  }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} / ${hours}:${minutes}:${seconds}`;
};

export const formatYYMMDD_TIME = (dateString) => {
  if (dateString === null) {
    return "";
  }

  const date = new Date(dateString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} / ${hours}:${minutes}:${seconds}`;
};

export const hyphenFormatter = (value) => {
  const input = value.replace(/-/g, "").replace(/\D/g, "");
  let formattedInput;

  if (input.length <= 3) {
    formattedInput = input;
  } else if (input.length <= 7) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3)}`;
  } else if (input.length < 11) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 11)}`;
  } else if (input.length === 11) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 12)}`;
  } else {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`;
  }

  return formattedInput;
};

export const formatNumber = (value) => {
  return parseInt(value).toLocaleString("ko-KR");
};

export const formatPhoneNumber = (value) => {
  const input = value.replace(/-/g, ""); // 기존의 하이픈 제거
  let formattedInput;

  if (input.length <= 3) {
    formattedInput = input;
  } else if (input.length <= 7) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3)}`;
  } else if (input.length < 11) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 11)}`;
  } else if (input.length === 11) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 12)}`;
  } else {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`;
  }
  return formattedInput;
};

export const formatBusinessNumber = (value) => {
  const input = value.replace(/-/g, ""); // 기존의 하이픈 제거
  let formattedInput;

  if (input.length <= 3) {
    formattedInput = input;
  } else if (input.length <= 5) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3)}`;
  } else if (input.length < 10) {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 5)}-${input.slice(5, 10)}`;
  } else {
    formattedInput = `${input.slice(0, 3)}-${input.slice(3, 5)}-${input.slice(5, 10)}`;
  }
  return formattedInput;
};

export const formatORDER_PAYMENT_TYPE = (value) => {
  const res = value === "pm1" ? "일반" : value === "pm2" ? "무통장" : "?";

  return res;
};
